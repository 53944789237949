import { BoxFolder } from "@modules/box/types/index.js";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../tailwind.config.js";
import { CareerStageOption } from "@common/types";
import { FingerprintScoreCategories } from "@modules/fingerprint/types";
import { Config } from "tailwindcss/types/config.js";

const FingerprintCategories: (keyof FingerprintScoreCategories)[] = [
  "INVESTMENTS",
  "INSURANCE",
  "CASH",
  "DEBT",
  "TAX",
  "ESTATE",
  "CAREER",
];

const FingerprintCategoryMapping: Record<
  keyof FingerprintScoreCategories,
  string
> = {
  INVESTMENTS: "Investment",
  TAX: "Tax",
  ESTATE: "Estate",
  INSURANCE: "Risk & Insurance",
  CAREER: "Career",
  DEBT: "Debt Management",
  CASH: "Cash Management",
};

const enum CareerStage {
  MEDICAL_STUDENT = "MEDICAL_STUDENT",
  RESIDENT = "RESIDENT",
  RETIRED_PHYSICIAN = "RETIRED_PHYSICIAN",
  PRACTICING_PHYSICIAN = "PRACTICING_PHYSICIAN",
}

const enum CareerStageExpanded {
  MEDICAL_STUDENT = "MEDICAL_STUDENT",
  RESIDENT = "RESIDENT",
  RETIRED_PHYSICIAN = "RETIRED_PHYSICIAN",
  PRACTICING_PHYSICIAN_EARLY = "PRACTICING_PHYSICIAN_EARLY",
  PRACTICING_PHYSICIAN_MID = "PRACTICING_PHYSICIAN_MID",
  PRACTICING_PHYSICIAN_LATE = "PRACTICING_PHYSICIAN_LATE",
}

const CareerStageMapping: Record<CareerStage | CareerStageExpanded, string> = {
  [CareerStage.MEDICAL_STUDENT]: "Medical Student",
  [CareerStage.RESIDENT]: "Resident",
  [CareerStage.RETIRED_PHYSICIAN]: "Retired Physician",
  [CareerStage.PRACTICING_PHYSICIAN]: "Practicing Physician",
  [CareerStageExpanded.PRACTICING_PHYSICIAN_EARLY]:
    "Early-Stage Practicing Physician",
  [CareerStageExpanded.PRACTICING_PHYSICIAN_MID]:
    "Mid-Stage Practicing Physician",
  [CareerStageExpanded.PRACTICING_PHYSICIAN_LATE]:
    "Late-Stage Practicing Physician",
};

const CareerStageOptions: CareerStageOption[] = [
  {
    id: null,
    name: "Make a Selection",
    value: null,
    label: "Make a Selection",
  },
  {
    id: CareerStage.MEDICAL_STUDENT,
    name: CareerStageMapping[CareerStage.MEDICAL_STUDENT],
    value: CareerStage.MEDICAL_STUDENT,
    label: CareerStageMapping[CareerStage.MEDICAL_STUDENT],
  },
  {
    id: CareerStage.RESIDENT,
    name: CareerStageMapping[CareerStage.RESIDENT],
    value: CareerStage.RESIDENT,
    label: CareerStageMapping[CareerStage.RESIDENT],
  },
  {
    id: CareerStage.PRACTICING_PHYSICIAN,
    name: CareerStageMapping[CareerStage.PRACTICING_PHYSICIAN],
    value: CareerStage.PRACTICING_PHYSICIAN,
    label: CareerStageMapping[CareerStage.PRACTICING_PHYSICIAN],
  },
  {
    id: CareerStage.RETIRED_PHYSICIAN,
    name: CareerStageMapping[CareerStage.RETIRED_PHYSICIAN],
    value: CareerStage.RETIRED_PHYSICIAN,
    label: CareerStageMapping[CareerStage.RETIRED_PHYSICIAN],
  },
];

const enum AdvisorRoutes {
  Households = "/households",
  Settings = "/settings",
}

// TODO: add all routes to this file
const enum ClientRoutes {
  Onboarding = "/welcome",
  Overview = "/overview",
  FinancialPlan = "/financial-plan",
  DocumentVault = "/document-vault",
  TaskAndActions = "/tasks-and-actions",
  ForYou = "/for-you",
  ForYouCalculators = "/for-you/calculators",
  AdvisoryTeam = "/advisory-team",
  Investments = "/investments",
  Accounts = "/accounts",
  Spending = "/spending",
  Budgets = "/budgets",
  Settings = "/settings",
  Logout = "/api/auth/logout",
  Disclosures = "/disclosures",
  PhysicianOnFire = "/physician-on-fire",
  // Currently, only used for impersonation
  Connections = "/connections",
}

const HEADER_TITLE_BY_PATH = {
  [ClientRoutes.Overview]: "360° View",
  [ClientRoutes.FinancialPlan]: "Financial Plan",
  [ClientRoutes.DocumentVault]: "Document Vault",
  [ClientRoutes.TaskAndActions]: "Tasks and Actions",
  [ClientRoutes.ForYou]: "For You",
  [ClientRoutes.AdvisoryTeam]: "Advisory Team",
  [ClientRoutes.Investments]: "Managed Investments",
  [ClientRoutes.Accounts]: "Accounts",
  [ClientRoutes.Spending]: "Spending",
  [ClientRoutes.Budgets]: "Budgets",
  [ClientRoutes.Settings]: "Settings",
  [ClientRoutes.Disclosures]: "Disclosures",
  [ClientRoutes.PhysicianOnFire]: "Physician On Fire",
  [ClientRoutes.Connections]: "Manage MX",
};

const HEADER_TITLE_BY_PATH_COBRANDED = {
  ...HEADER_TITLE_BY_PATH,
  [ClientRoutes.Overview]: "Dashboard",
};

type ColorVariants = {
  "primary": string;
  "secondary": string;
  "tertiary": string;
  "disabled": string;
  "white": string;
  "danger": string;
  "danger-light": string;
};

const fullConfig = resolveConfig(tailwindConfig as unknown as Config);
const themeColors = fullConfig.theme
  ?.colors as typeof tailwindConfig.theme.colors;
const themeBorderColors = fullConfig.theme
  ?.borderColor as typeof tailwindConfig.theme.colors & ColorVariants;
const themeTextColors = fullConfig.theme
  ?.textColor as typeof tailwindConfig.theme.colors & ColorVariants;

const enum themeBreakpoints {
  lg = 1200,
  md = 996,
  sm = 768,
  xs = 480,
  xxs = 0,
}

const createFormData = (
  appendFile: Blob,
  fileName: string,
  folder: BoxFolder,
  rootFolderId: string
) => {
  const formData = new FormData();
  formData.append("file", appendFile);

  formData.append(
    "attributes",
    JSON.stringify({
      name: fileName,
      parent: {
        id: folder.id.length > 0 ? folder.id : rootFolderId,
      },
    })
  );

  return formData;
};

const enum HouseholdInvitationStatus {
  Pending = "Pending",
  Active = "Active",
  Expired = "Expired",
}

const enum CustomCookies {
  HasLoggedInBefore = "HasLoggedInBefore",
}

const enum ExternalLinks {
  PrivacyPolicy = "https://www.earnedwealth.com/legal/privacy-policy",
}

const CREATED_BY_ADVISOR_CLAIM = "https://earnedwealth.com/created-by-advisor";

const enum ZeroStateModules {
  FinancialPlan = "financial-plan",
  Accounts = "accounts",
  Investments = "investments",
  CobrandedExperience = "cobranded-experience",
  ThreeSixtyView = "360-view",
}

enum FeatureFlags {
  UMP_EXPERIENCE = "umpExperience",
  JMMG_EXPERIENCE = "jmmgExperience",
  MGP_WIZARD = "mgpWizard",
  MGP_WIZARD_ADVISOR = "mgpWizardAdvisor",
}

export {
  AdvisorRoutes,
  ClientRoutes,
  CareerStage,
  CareerStageExpanded,
  CareerStageMapping,
  CareerStageOptions,
  CustomCookies,
  ExternalLinks,
  FingerprintCategories,
  FingerprintCategoryMapping,
  HouseholdInvitationStatus,
  themeBreakpoints,
  themeColors,
  themeTextColors,
  themeBorderColors,
  createFormData,
  CREATED_BY_ADVISOR_CLAIM,
  ZeroStateModules,
  HEADER_TITLE_BY_PATH,
  HEADER_TITLE_BY_PATH_COBRANDED,
  FeatureFlags,
};
