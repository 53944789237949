import request from "@common/request";
import { get } from "lodash";
import {
  QueryClient,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";
import axios, { Method, AxiosError, AxiosRequestConfig } from "axios";
import { FORME_API_HOST, FORME_API_TOKEN_QUERY_KEY } from "./constants";

const retryWithExpiredTokenRefresh = (
  failureCount: number,
  error: AxiosError,
  queryClient: QueryClient
) => {
  if (error) {
    // response.status sometimes is undefined, we are not sure if the following code actually works.
    if (error.response?.status === 403) {
      console.debug("Invalidating Forme API JWT and retrying...");
      queryClient.invalidateQueries([FORME_API_TOKEN_QUERY_KEY]);
    }
  }
  return failureCount < 3; // max 3 retries
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useAccessToken = (options: UseQueryOptions<any, any, any, any> = {}) => {
  return useQuery(
    FORME_API_TOKEN_QUERY_KEY,
    () => request<{ accessToken: string }>("GET", "/api/forme/token"),
    {
      ...options,
      cacheTime: 55 * 60 * 1000, // 55 minutes (in milliseconds) < 1 hour token duration
      refetchInterval: 55 * 60 * 1000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
    }
  );
};

const useAxiosConfig = <T>({
  method,
  url,
  params,
  accessToken,
}: {
  method: Method;
  url: string;
  params?: Record<string, string>;
  accessToken: string;
}) => {
  const config: AxiosRequestConfig<T> = {
    method,
    baseURL: FORME_API_HOST,
    url,
    params,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return config;
};

// TODO: figure out how to more accurately type the 'options' parameter
const useBaseQueryOptions = <infinite extends boolean>(
  accessToken: string,
  queryClient: QueryClient,
  options: infinite extends true
    ? UseInfiniteQueryOptions<any, any, any, any, any>
    : UseQueryOptions<any, any, any, any>
) => {
  const baseOptions: typeof options = {
    retry: (failureCount, error) =>
      retryWithExpiredTokenRefresh(
        failureCount,
        error as AxiosError,
        queryClient
      ),
    enabled: (options.enabled ?? true) && !!accessToken,
  };

  return { ...options, ...baseOptions };
};

const useBaseMutationOptions = <T, V>(
  queryClient: QueryClient,
  options: UseMutationOptions<T, AxiosError<T>, V>
) => {
  const baseOptions: typeof options = {
    retry: (failureCount, error) =>
      retryWithExpiredTokenRefresh(
        failureCount,
        error as AxiosError,
        queryClient
      ),
  };

  return { ...options, ...baseOptions };
};

const useFormeBackendApi = <T>({
  method,
  path,
  dataKey = "",
  params = {},
  options = {},
}: {
  method: Method;
  path: string;
  params?: Record<string, string>;
  dataKey?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any, any, any, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): UseQueryResult<T, AxiosError> => {
  const { data } = useAccessToken({
    enabled: options.enabled !== undefined ? options.enabled : true, // default to true if not provided
  });
  const accessToken = data?.accessToken ?? "";
  const config: AxiosRequestConfig = useAxiosConfig({
    method,
    url: path,
    params,
    accessToken,
  });

  const queryClient = useQueryClient();
  const queryOptions = useBaseQueryOptions<false>(
    accessToken,
    queryClient,
    options
  );

  return useQuery(
    [`forme/${path}/${dataKey}`, params],
    async (): Promise<T> => {
      console.log("Forme Backend Request:", config);
      return axios
        .request(config)
        .then((res) => (dataKey ? get(res.data, dataKey) : res.data));
    },
    queryOptions
  );
};

const useInfiniteFormeBackendApi = <T>({
  method,
  path,
  dataKey = "",
  params = {},
  options = {},
}: {
  method: Method;
  path: string;
  params?: Record<string, string>;
  dataKey?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseInfiniteQueryOptions<any, any, any, any, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): UseInfiniteQueryResult<T, AxiosError> => {
  const { data } = useAccessToken();
  const accessToken = data?.accessToken ?? "";
  const config: AxiosRequestConfig = useAxiosConfig({
    method,
    url: path,
    params,
    accessToken,
  });

  const queryClient = useQueryClient();
  const queryOptions = useBaseQueryOptions<true>(
    accessToken,
    queryClient,
    options
  );

  return useInfiniteQuery(
    [`forme/${path}/${dataKey || ""}`, params],
    async (o): Promise<T> => {
      console.log("Forme Backend Request:", config);
      return axios
        .request({
          ...config,
          params: { ...config.params, pageToken: o.pageParam },
        })
        .then((res) => (dataKey ? get(res.data, dataKey) : res.data));
    },
    queryOptions
  );
};

const useMutationFormeBackendApi = <T, B>({
  method,
  path,
  dataKey,
  params = {},
  options = {},
}: {
  method: Method;
  path: string;
  params?: Record<string, string>;
  dataKey?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseMutationOptions<T, AxiosError<T>, B>;
}): UseMutationResult<T, AxiosError, B> => {
  const { data } = useAccessToken();
  const accessToken = data?.accessToken ?? "";
  const config: AxiosRequestConfig = useAxiosConfig<B>({
    method,
    url: path,
    params,
    accessToken,
  });

  const mutationFn: UseMutationOptions<
    T,
    AxiosError<T>,
    B
  >["mutationFn"] = async (data): Promise<T> => {
    console.log("Forme Backend Request:", config);
    return axios
      .request({ ...config, params: config.params, data })
      .then((res) => (dataKey ? get(res.data, dataKey) : res.data));
  };

  const queryClient = useQueryClient();
  const mutationOptions = useBaseMutationOptions(queryClient, options);

  return useMutation<T, AxiosError<T>, B>(mutationFn, mutationOptions);
};

export {
  useAccessToken,
  useFormeBackendApi,
  useInfiniteFormeBackendApi,
  useMutationFormeBackendApi,
};
