import Head from "next/head";
import Hotjar from "@hotjar/browser";
import AdvisorSidebar from "@common/components/AdvisorSidebar";
import ClientSidebar from "@common/components/ClientSidebar";
import { Suspense, ReactNode, useMemo, useEffect } from "react";
import React from "react";
import useStore from "@common/state";
import useAdvisorStore from "@modules/advisor/state";
import { useUser } from "@auth0/nextjs-auth0";
import { useClientByUserId } from "@modules/advisor/queries";
import AdvisorChat from "@common/components/AdvisorChat";
import { useIsAdvisor, usePoFExperience, useRouter } from "@common/hooks";
import { useHeapInitialize } from "@modules/heap/hooks";
import { AdvisorRoutes, ClientRoutes } from "@common/constants";
import LogoutModal from "@common/components/LogoutModal";
import MobileMenu from "@common/components/MobileMenu";
import Script from "next/script";
import { resetPersistedStores } from "@common/utils";
import { Button, Typography } from "@earned/core";
import { useHousehold, useMeClient } from "@common/queries";
import { ReferralBanner } from "@common/components/ReferralBanner";
import { useBoolean } from "usehooks-ts";
import cx from "classnames";
import moment from "moment";
import { TalkToAnAdvisorModal } from "@common/components/TalkToAnAdvisorModal";
import LoadingIndicator from "@common/components/LoadingIndicator";
import { HeapScript } from "@common/components/HeapScript";

function DefaultLayout({ children }: { children: ReactNode }) {
  const router = useRouter();
  const { isSidebarExpanded, activeSidebarLinkName } = useStore();
  const { user } = useUser();
  const { impersonateClientUserId, setImpersonateClientUserId } =
    useAdvisorStore();
  const { isAdvisor, isAdvisorPortal } = useIsAdvisor();
  const { isLoading: isLoadingHeap } = useHeapInitialize(
    typeof window !== "undefined" && window.heap,
    isAdvisor as boolean,
    user as Forme.Claims
  );

  // referral program ends at end of year. Putting this here so it will hide automatically until we remove it after the new year:
  const {
    value: isReferralOpen,
    setTrue: showReferral,
    setFalse: closeReferral,
  } = useBoolean(false);
  const { data: household } = useHousehold({
    enabled: !!user,
  });
  const { data: client } = useMeClient({
    enabled: !!user,
  });
  useEffect(() => {
    const isLoaded = !!household;
    const isMember = !household?.currentProduct;
    const isWealthDiagnosticClient =
      household?.currentProduct?.templateId === "wealthDiagnostic";
    if (isLoaded && (isMember || !isWealthDiagnosticClient)) {
      showReferral();
    }
  }, [household, showReferral]);
  useEffect(() => {
    // NOTE: This is only enabled in Business+ Plans.
    // Currently, it does nothing.
    if (
      process.env.NEXT_PUBLIC_VERCEL_ENV === "production" &&
      client &&
      !impersonateClientUserId
    ) {
      Hotjar.identify(client.email, {
        firstName: client.firstName ?? "",
        lastName: client.lastName ?? "",
      });
    }
  }, [client, impersonateClientUserId]);
  const isOnboarding = useMemo(
    () => router.pathname === ClientRoutes.Onboarding,
    [router]
  );
  const { data } = useClientByUserId({
    userId: impersonateClientUserId ?? "",
  });
  const impersonatedClient = data?.client;
  const subTitle = isOnboarding
    ? " - Welcome"
    : activeSidebarLinkName
    ? ` - ${activeSidebarLinkName} `
    : "";
  const title = "Earned Wealth" + subTitle;
  const pageContentViewportClassName = useMemo(() => {
    // compensate for the heights of banners which push the rest of the page content down:
    return cx("relative flex w-full flex-1 flex-row", {
      // Impersonation Mode:
      "h-[calc(100%-104px)]": !!impersonateClientUserId && isReferralOpen,
      "h-[calc(100%-40px)]": !!impersonateClientUserId && !isReferralOpen,
      // Client Mode:
      "h-[calc(100%-64px)]": isReferralOpen,
      "h-full": !isReferralOpen,
    });
  }, [impersonateClientUserId, isReferralOpen]);

  const { isPof, isWaiting: isWaitingForIsPoF } = usePoFExperience();

  return (
    <main className="absolute flex h-full w-full flex-col overflow-y-hidden">
      <Head>
        <title>{title}</title>
        <meta name="description" content="Earned Wealth" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      {process.env.NEXT_PUBLIC_VERCEL_ENV === "production" && (
        <>
          <Script
            src="https://www.googletagmanager.com/gtag/js?id=G-4BTY1D6QSM"
            strategy="afterInteractive"
          />
          <Script id="google-analytics" strategy="afterInteractive">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){window.dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-4BTY1D6QSM');
            `}
          </Script>
        </>
      )}
      <HeapScript />
      {impersonateClientUserId && (
        <div className="relative z-[100] flex h-10 w-full flex-none flex-row items-center justify-center border-b border-primary bg-green-50 bg-opacity-50 font-medium">
          <Typography variant="body-sm" color="primary" weight="medium">
            Impersonating {impersonatedClient?.firstName}{" "}
            {impersonatedClient?.lastName} ({impersonatedClient?.email})
          </Typography>
          <div className="absolute right-3 flex-none">
            <Button
              variant="primary"
              size="sm"
              onClick={async () => {
                window.heap?.resetIdentity();
                setImpersonateClientUserId("");
                await resetPersistedStores();
                window.location.assign(AdvisorRoutes.Households);
              }}
            >
              EXIT
            </Button>
          </div>
        </div>
      )}
      {isAdvisor || impersonateClientUserId ? null : (
        <ReferralBanner isOpen={isReferralOpen} close={closeReferral} />
      )}
      {isWaitingForIsPoF ? (
        <LoadingIndicator isVisible />
      ) : (
        <>
          {!isOnboarding && (
            <MobileMenu isAdvisor={isAdvisorPortal as boolean} />
          )}
          <div className={pageContentViewportClassName}>
            <Suspense
              fallback={
                <div
                  className={
                    "h-full bg-purple-1-900 " +
                    (isSidebarExpanded ? "w-72" : "w-25")
                  }
                ></div>
              }
            >
              <LogoutModal />
              {!isOnboarding && !isLoadingHeap && (
                <>{isAdvisorPortal ? <AdvisorSidebar /> : <ClientSidebar />}</>
              )}
              <AdvisorChat />
              <TalkToAnAdvisorModal />
            </Suspense>
            {children}
          </div>
        </>
      )}
    </main>
  );
}

export default DefaultLayout;
